/**
 * Created by Alex on 22/08/2016.
 */

// var ws
var logged
wsconnector = {

    //Open a websocket connection
    create: function (url) {
        ws = new WebSocket(url);
        ws.onopen = function (evt) {
            logged = false;
            wsconnector.eventListener(evt, "channel");
        }
        ws.onmessage = function (evt) {
            if ((JSON.parse(evt.data)).ready != "" && (JSON.parse(evt.data)).ready) {
                logged = true
                wsconnector.eventListener(evt, "session");
            }else if((JSON.parse(evt.data)).errorCode){
                wsconnector.eventListener(JSON.parse(evt.data).message,"error");
            }
            else {
                wsconnector.eventListener(evt, "message");
            }
        }
        ws.onerror = function (evt) {
            wsconnector.eventListener(evt, "error");
        }
        ws.onclose = function (evt) {
            wsconnector.eventListener(evt, "channel");
        }
        return ws
    },

    //allows to login with mycook notification server
    login: function (token, region) {
        //if channel isn't open throws an error
        if (ws.readyState == ws.CONNECTING) {
            wsconnector.eventListener('Channel is still CONNECTING', "session");
        }

        if (ws.readyState == ws.OPEN) {
            var lg = {
                tokenLogin: {
                    mycooktoken: token,
                    region: region
                }
            };
            ws.send(JSON.stringify(lg));
        }
    },

    //method to logout a user from server
    logout: function (username, region) {
        var lg = {
            logout: {
                user: username,
                region: region
            }
        };
        ws.send(JSON.stringify(lg));
    },

    //close websocket with server
    disconnect: function () {
        ws.close();
    },
    //generic method for send messages to the notification server
    sendMessage: function (data) {
        if (isLogged()) {
            if (data != "") {
                if (checkJSON(data)) {
                    ws.send(data);
                    wsconnector.eventListener("SEND : " + data, "message");
                }
            } else {
                wsconnector.eventListener("Message empty", "error");
            }
        } else {
            wsconnector.eventListener("NOT LOGGED", "session");
        }
    },
    eventListener: function (evt, type) {
        return evt, type
    }

}

function isLogged() {

    return logged
}

function checkJSON(str) {
    try {
        var o = JSON.parse(str);
        if (o && typeof o === "object") {
            return true;
        }
    } catch (e) {
        return false;
    }
    return true;
}